@charset "utf-8";



// Our variables
$base-font-family: 'Lato', 'Open Sans', Helvetica, Arial, sans-serif !important;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.6em;

$spacing-unit:     15px;

$text-color:       #111;
$background-color: #f9f9f9;
$brand-color:      #2a7ae2;
$brand-top-color:  #2583ad;
$hover-color:      #DD1144;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

$code-backgroud-color: #29281e;
$code-font-color:  #eee;  


// Width of the content area
$content-width:    960px;
$page-width:       1000px;

$on-palm:          600px;
$on-laptop:        800px;



// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "_syntax-highlighting-native", // "syntax-highlighting"
        "posts-content",
        "_contact-download-posts-content",
        "_downloads-content",
        "_semba-cmesher-osemba-ugrfdtd-zmesher-content",
        "_members-content"
;

@import url('http://fonts.googleapis.com/css?family=Open+Sans');
@import url('http://fonts.googleapis.com/css?family=Lato');
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
